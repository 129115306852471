window.TNCMS = window.TNCMS || {};
window.TNCMS.Access = TNCMS.Access || [];

window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.offers || (__tnt.subscription.offers = {});

__tnt.subscription.offers.transactionDetails = function(modalEl, receiptTab, transactionData) {
    function moneyFormat(locale, money, value){
          return value.toLocaleString(locale, { style: 'currency', currency: money });
    }

    if(modalEl && receiptTab && transactionData && transactionData.transaction_id){
        var templateEl = receiptTab.querySelector('template'),
            outputEl = receiptTab.querySelector('.modal-receipt-output'),
            userNameEl = receiptTab.querySelector('.username'),
            ratePrice = sessionStorage.getItem('__tnt.service.rate.price'),
            rateName = sessionStorage.getItem('__tnt.service.rate.name'),
            total = "",
            startDate = null,
            expireDate = null,
            expireDateString = "",
            receiptElements = [
               {
                  'element': '.transaction-id',
                  'html': transactionData.transaction_id
               }
            ],
            locale = "en-US",
            currency = "USD";

        if(__tnt.user.screenName && userNameEl){
            userNameEl.innerHTML = " " + __tnt.user.screenName;
        }

        if(transactionData.start_date){
            startDate = new Date(transactionData.start_date);
            receiptElements.push({'element': '.service-start', 'html': startDate.toLocaleDateString(locale, {month: 'long', day: 'numeric', year: 'numeric' }) });
        }

        if(transactionData.currency == "CDN"){
            locale = "en-CA";
            currency = "CDN";
        }

        // Populate modal receipt elements
        receiptElements.push({'element': '.service-name', 'html': transactionData.service_name });
        receiptElements.push({'element': '.service-rate', 'html': moneyFormat(locale, currency, Number(transactionData.rate_price)) });

        if(transactionData.tax){
            total = Number(transactionData.rate_price) + Number(transactionData.tax);
            receiptElements.push({'element': '.service-tax', 'html': moneyFormat(locale, currency, Number(transactionData.tax)) });
            receiptElements.push({'element': '.service-total', 'html': moneyFormat(locale, currency, total) });
        }

        outputEl.innerHTML = "";

        __tnt.subscription.access.displayReceipt = true;
        __tnt.template(templateEl, outputEl, false, receiptElements, function(t){
            var taxElements = t.querySelectorAll('.tnt-tax-detail');

            if(transactionData.tax && taxElements){
                taxElements.forEach(function(el){
                    el.classList.remove('hidden');
                });
            }
        });
    }
};

document.addEventListener("DOMContentLoaded", function (event) {
    window.TNCMS.Access.push(function () {
        __tnt.subscription.access.status.trigger(userStatus)
    });

    if(typeof(__tnt.subscription.access.promises)=="undefined"){
        function getProduct(){
            if(__tnt.subscription.offers.productData){
                return __tnt.subscription.offers.productData;
            } else if(sessionStorage.getItem('__tnt.subscription.offers.productData')){
                __tnt.subscription.offers.productData = JSON.parse(sessionStorage.getItem('__tnt.subscription.offers.productData'));
        
                return __tnt.subscription.offers.productData;
            }
        }

        var params = new URLSearchParams(document.location.search),
            transactionId = params.get('transaction_id'),
            token = params.get('token'),
            paypalForm = document.getElementById('form-tncms-paypal-purchase'),
            modalEl = document.getElementById('access-offers-modal'),
            modalTabPurchaseSuccess = document.getElementById('user-modal-tab-purchase-success'),
            purchaseGiftEl = null,
            purchaseStandardEl = null,
            serviceName = null,
            serviceRateName = null,
            serviceRatePrice = null,
            taxRate = null,
            date = new Date(),
            receiptViewed = false,
            productData = getProduct(),
            purchaseType = "standard",
            currency = "USD";

        if(sessionStorage.getItem('__tnt.subscription.transaction.viewed.' + transactionId) == 'true'){
            receiptViewed = true;
        }
       
        if(modalTabPurchaseSuccess){
            purchaseGiftEl = modalTabPurchaseSuccess.querySelector('.purchase-gift');
            purchaseStandardEl = modalTabPurchaseSuccess.querySelector('.purchase-standard');
        }
        if(purchaseGiftEl && purchaseStandardEl){
            if(sessionStorage.getItem('__tnt.subscription.offer.purchaseType')=='gift'){
                purchaseGiftEl.hidden = false;
                purchaseStandardEl.hidden = true;
            } else {
                purchaseGiftEl.hidden = true;
                purchaseStandardEl.hidden = false;
            }
        }

        if(productData && productData.currency){
            currency = productData.currency;
        }

        if(transactionId){
            try{
                serviceName = sessionStorage.getItem('__tnt.service.name');
                serviceRateName = sessionStorage.getItem('__tnt.service.rate.name');
                serviceRatePrice = sessionStorage.getItem('__tnt.service.rate.price');
                taxRate = sessionStorage.getItem('__tnt.service.rate.tax');
                
            __tnt.subscription.offers.transactionDetails(modalEl, modalTabPurchaseSuccess, { 
                    transaction_id: transactionId,
                    service_name: serviceName, 
                    service_rate: serviceRateName,
                    rate_price: serviceRatePrice,
                    start_date: date.getTime(),
                    tax: taxRate,
                    currency: currency
                    });
            
                __tnt.user.loginSuccess = true;
                __tnt.subscription.access.showTab(modalTabPurchaseSuccess, 'access-offers-modal');
        
            } catch(e){ 
                console.log(e);
                __tnt.subscription.access.showTab(modalTabPurchaseSuccess, 'access-offers-modal');
            }
     
            $(modalEl).find('.modal').modal();
        }
    }
});
